import { createUseStyles } from 'react-jss'

import { COLOR, MQ, TYPOGRAPHY } from '@constants'

export const useStyles = createUseStyles({
  '@keyframes blink': {
    '0%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.2,
    },
  },

  wrapper: {
    backgroundColor: COLOR.YELLOW,
    color: COLOR.BLACK,
  },
  container: {
    width: '74%',
    margin: 'auto',
    padding: [84, 0],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET]: {
      width: '90%',
    },
    [MQ.TABLET_LARGE]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [MQ.DESKTOP]: {
      width: '80%',
      maxWidth: 1248,
      margin: 'auto',
      padding: [120, 0],
    },
  },
  title: {
    [MQ.TABLET]: {
      width: '80%',
    },
    [MQ.TABLET_LARGE]: {
      width: '50%',
    },
    [MQ.DESKTOP]: {
      width: '50%',
    },
  },
  paragraph: {
    padding: [32, 0, 0],
  },
  form: {
    padding: [40, 0, 0],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    [MQ.TABLET]: {
      flexDirection: 'row',
      width: '80%',
      margin: 'auto',
    },
    [MQ.TABLET_LARGE]: {
      width: '50%',
      padding: 0,
    },
  },
  email: {
    width: '100%',
    [MQ.TABLET]: {
      width: '60%',
    },
    [MQ.DESKTOP]: {
      width: '64.7%',
    },
  },
  button: {
    backgroundColor: COLOR.BLACK,
    marginTop: 18,
    [MQ.TABLET]: {
      margin: [0, 0, 0, 0],
    },
  },
  ctaButton: {
    [MQ.TABLET]: {},
  },
  label: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontWeight: 600,
  },
  buttonText: {
    padding: [15, 28, 12],
    textDecoration: 'none',
    display: 'inline-block',
    color: COLOR.WHITE,
    [MQ.TABLET]: {
      padding: [20, 28, 16],
    },
  },
  botInput: {
    position: 'absolute',
    left: -5000,
  },
  submitting: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      animation: '$blink 1.4s ease infinite',
    },
    '& span:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
  submitted: {
    margin: 'auto',
    background: 'transparent',
    width: '100%',
    cursor: 'default',
  },
  buttonError: {
    backgroundColor: COLOR.GREY_2,
  },
  inputError: {
    color: COLOR.RED,
  },
  input: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontWeight: 600,
    fontSize: 18,
    [MQ.DESKTOP]: {
      fontSize: 21,
    },
  },
})
