export const FORM = {
  METHOD: 'POST',
  ACTION: 'https://riffcreativestudio.us7.list-manage.com/subscribe/post',
  NAME: 'cpp-mailchimp',
}

export const FIELDS = {
  EMAIL: {
    NAME: 'email_mailchimp',
    LABEL: 'Email',
    VALIDATIONS: {
      required: true,
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    },
  },
  NAME: {
    NAME: 'name',
    LABEL: 'Name',
    VALIDATIONS: {
      required: true,
    },
  },
}
