import React, { useState, useEffect } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useMeasure, useMedia, useLockBodyScroll } from 'react-use'
import cn from 'classnames'
import Headroom from 'react-headroom'
import { Media } from 'gatsby-plugin-fresnel'
import { useLocation } from '@reach/router'

import { useStyles } from './styles'
import { Text, Svg } from '@atoms'
import { ROUTES } from '@constants'
import { dotPath, isNilOrEmpty } from 'ramdasauce'

export const Header = () => {
  const classes = useStyles()
  const data = useStaticQuery(headerQuery)
  const layout = dotPath('contentfulHeader.pageLayout', data)

  const [isOpen, setIsOpen] = useState(false)
  const [hovered, setHovered] = useState()
  const [current, setCurrent] = useState()

  const [ref, { height }] = useMeasure()
  const location = useLocation()
  const isMobile = useMedia(`(max-width: 1023px)`, true)
  const showNav = height <= 165
  const mobileStyles = !showNav || isMobile
  const navContainerClasses = cn({
    [classes.navContainer]: true,
    [classes.heightBroke]: mobileStyles,
  })
  const handleClick = () => setIsOpen(!isOpen)
  useLockBodyScroll(isOpen)
  useEffect(() => {
    if (!showNav) {
      setIsOpen(false)
    }
  }, [showNav])

  useEffect(() => {
    setCurrent(location.pathname)
  }, [location])

  const firstPath = (path) => !isNilOrEmpty(path) && path.split('/')[1]
  const datePath = (path) => !isNilOrEmpty(path) && path.slice(0, 10)
  const dateReg =
    /^[\/\-]\d{4}[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])$/ //eslint-disable-line

  const isBlogPost = dateReg.test(datePath(current))

  const menuButtonClasses = cn({
    [classes.menuButton]: true,
    [classes.menuButtonOpen]: isOpen,
  })
  const linesClasses = cn({
    [classes.lines]: true,
    [classes.linesOpen]: isOpen,
  })
  const sideNavClasses = cn({
    [classes.sideNav]: true,
    [classes.sideNavOpen]: isOpen,
  })

  const links =
    !isNilOrEmpty(layout) &&
    layout.map((page) => {
      const color = !isNilOrEmpty(dotPath('color', page))
        ? dotPath('color', page)
        : 'blue'
      const pageData = {
        name: dotPath('title', page),
        url: `/${dotPath('urlSlug', page)}/`,
        svg: dotPath('headerImage.file.url', page),
        hoverSvg: dotPath('hoveredHeaderImage.file.url', page),
        hover: classes[color.toLowerCase()],
        active: classes[`${color.toLowerCase()}Active`],
      }
      return pageData
    })

  const renderLinks = (links) => {
    return links.map((link, i) => {
      const svgHovered =
        hovered === `${link.url}${i}` ? link.hoverSvg : link.svg
      const isCurrent = isBlogPost
        ? firstPath('/blog') === firstPath(link.url)
        : firstPath(current) === firstPath(link.url)
      const linkClasses = cn({
        [classes.link]: true,
        [link.hover]: true,
        [link.active]: isCurrent,
      })
      return (
        <Link
          to={link.url}
          key={link.url}
          className={linkClasses}
          onMouseOver={() => setHovered(`${link.url}${i}`)}
          onMouseLeave={() => setHovered(``)}
        >
          <div className={classes.svgWrapper}>
            <img src={isCurrent ? link.hoverSvg : svgHovered} alt={link.name} />
          </div>
          <Text size="subhead2" className={classes.name}>
            {link.name}
          </Text>
        </Link>
      )
    })
  }

  const renderLinksMobile = (links) => {
    return links.map((link, i) => {
      const isCurrent = current === link.url
      const linkClasses = cn({
        [classes.link]: true,
        [link.active]: isCurrent,
      })
      return (
        <Link to={link.url} key={link.url} className={linkClasses}>
          <Text size="header4" className={classes.name}>
            {link.name}
          </Text>
        </Link>
      )
    })
  }

  return (
    <Headroom className={classes.headroom}>
      <header className={classes.headerWrapper}>
        <div className={classes.headerContainer}>
          <div className={classes.logoContainer}>
            <Link to={ROUTES.HOME} className={classes.home}>
              <Svg name="logo" />
            </Link>
          </div>
          {showNav && !isMobile ? (
            <Media
              greaterThanOrEqual="tablet"
              className={classes.fresnelContainer}
            >
              <div className={navContainerClasses} ref={ref}>
                {renderLinks(links)}
              </div>
            </Media>
          ) : (
            <nav>
              <button onClick={handleClick} className={menuButtonClasses}>
                <div className={linesClasses} />
              </button>
              <div className={sideNavClasses}>
                <div className={classes.mobileLinksWrapper}>
                  {renderLinksMobile(links)}
                </div>
              </div>
            </nav>
          )}
        </div>
      </header>
    </Headroom>
  )
}

const headerQuery = graphql`
  query {
    contentfulHeader {
      pageLayout {
        title
        urlSlug
        color
        headerImage {
          gatsbyImageData
          file {
            url
            contentType
          }
        }
        hoveredHeaderImage {
          gatsbyImageData
          file {
            url
            contentType
          }
        }
      }
    }
  }
`
