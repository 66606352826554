import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import cn from 'classnames'
import { isNilOrEmpty, dotPath } from 'ramdasauce'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const ContentfulButton = ({ data, dark, whiteOutline }) => {
  const classes = useStyles()
  const buttonClasses = cn({
    [classes.button]: true,
    [classes.black]: dark,
    [classes.whiteOutline]: whiteOutline,
    // [classes.white]: dotPath('color', data) === 'White',
    // [classes.navy]: dotPath('color', data) === 'Navy',
  })

  const isButtonPresent = !isNilOrEmpty(data) && !isNilOrEmpty(data.buttonType)
  const buttonType =
    isButtonPresent &&
    data.buttonType.map((item, i) => {
      const typename = item.__typename
      switch (typename) {
        case 'ContentfulInternalLink': {
          const urlSlug = item.link.urlSlug
          // handles the homepage links since the url slug for that page is null by default
          const url = isNilOrEmpty(urlSlug) ? '' : urlSlug
          return (
            <Link to={`/${url}`} key={i} className={classes.link}>
              <Text size="body1" className={classes.buttonText}>
                {data.text}
              </Text>
            </Link>
          )
        }
        case 'ContentfulExternalLink': {
          return (
            <a
              href={dotPath('externalLinkUrl.externalLinkUrl', item)}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
              key={i}
            >
              <Text size="body1" className={classes.buttonText}>
                {data.text}
              </Text>
            </a>
          )
        }
        case 'ContentfulDownloadLink': {
          return (
            <a
              href={item.file.file.url}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
              key={i}
            >
              <Text size="body1" className={classes.buttonText}>
                {data.text}
              </Text>
            </a>
          )
        }
        case 'ContentfulPhoneNumberLink': {
          return (
            <a
              href={`tel:${item.phoneNumber}`}
              className={classes.link}
              key={i}
            >
              <Text size="body1" className={classes.buttonText}>
                {data.text}
              </Text>
            </a>
          )
        }

        default:
          return ''
      }
    })

  return <div className={buttonClasses}>{buttonType}</div>
}

ContentfulButton.propTypes = {
  data: PropTypes.object,
  dark: PropTypes.bool,
}

ContentfulButton.defaultProps = {
  data: {},
  dark: false,
}
