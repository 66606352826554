import { createUseStyles } from 'react-jss'

import { COLOR, MQ, Z_INDEX } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 600,
    backgroundColor: COLOR.GREY_1,
    [MQ.TABLET]: {
      maxHeight: 800,
    },
    [MQ.TABLET_LARGE]: {
      maxHeight: 900,
    },
    [MQ.DESKTOP_LARGE]: {
      maxHeight: 1400,
    },
  },
  image: {
    width: '100%',
    minHeight: 600,
    filter: 'grayscale(1)',
  },
  textBlock: {
    position: 'absolute',
    color: COLOR.WHITE,
    textAlign: 'center',
    zIndex: Z_INDEX.CONTENT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 200,
    width: '90%',
    [MQ.TABLET]: {
      width: '65%',
    },
    [MQ.TABLET_LARGE]: {
      width: '45%',
      maxWidth: 800,
    },
  },
  imageWrapper: {
    position: 'absolute',
    color: COLOR.WHITE,
    textAlign: 'center',
    zIndex: Z_INDEX.CONTENT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 200,
    width: '45%',
    [MQ.TABLET]: {
      width: '36%',
    },
    [MQ.TABLET_LARGE]: {
      width: '25%',
    },
  },
  centerImage: {
    width: '100%',
  },
  title: {
    marginBottom: 0,
  },
  titleHidden: {
    position: 'absolute',
    right: '200vw',
  },

  button: {
    marginTop: 30,
  },

  imageOverlay: {
    backgroundColor: 'rgb(32, 32, 32, 0.5)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: Z_INDEX.BACKGROUND,
  },
  navy: {
    color: COLOR.NAVY,
  },
  blue: {
    color: COLOR.BLUE,
  },
})
