import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FORM, FIELDS } from './form'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, InputText, Animated, FormButton, ContentfulButton } from '@atoms'

export const NewsletterForm = ({ data }) => {
  const ctaButtonOnly = data.ctaButtonOnly
  const ctaButton = data.ctaButton
  const classes = useStyles()

  const [flash, setFlash] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const {
    register,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
  })

  const onSubmit = async (data) => {
    setSubmitting(true)
    // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
    const { result, msg } = await addToMailchimp(data.email_mailchimp)

    if (result === 'success') {
      reset()
      setSubmitted(true)
      setFlash('')
    } else {
      // Removes the HTML returned in some response messages in case of error
      // replaces &amp; with an actual string ampersand
      setFlash(msg.split('<')[0].replace('&amp;', '&'))
    }

    setSubmitting(false)
  }

  const formSubmittable = () => {
    return isNilOrEmpty(errors) && !isNilOrEmpty(watch(FIELDS.EMAIL.NAME))
  }

  const submitClasses = cn({
    [classes.button]: true,
    [classes.buttonError]: !isNilOrEmpty(errors),
    [classes.submittable]: formSubmittable(),
    [classes.submitted]: submitted,
  })

  const wrapperClasses = cn({
    [classes.wrapper]: true,
  })
  return (
    <div className={wrapperClasses}>
      <div className={classes.container}>
        <Animated type="fade-in">
          {!isNilOrEmpty(data.title) && (
            <div className={classes.title}>
              <Text size="header2" tag="h3">
                {data.title}
              </Text>
            </div>
          )}
          {submitted && (
            <div className={submitClasses}>
              <Text tag="span" size="header2">
                Thank you for subscribing!
              </Text>
            </div>
          )}
          {!isNilOrEmpty(flash) && (
            <div className={classes.flash}>
              <Text tag="p" size="header2">
                {flash}
              </Text>
            </div>
          )}

          {!submitted && !ctaButtonOnly && (
            <form className={classes.form} name={FORM.NAME}>
              {/* this prevents bot sign ups */}
              <input
                className={classes.botInput}
                aria-hidden="true"
                type="text"
                name="b_ad060d125fb61e959019907a9_eba86e2d8c"
                tabIndex="-1"
              />
              {/* <div className={classes.email}>
                <InputText
                  name={FIELDS.NAME.NAME}
                  type="email"
                  label={FIELDS.NAME.LABEL}
                  inputClassName={classes.input}
                  register={register}
                  validations={FIELDS.NAME.VALIDATIONS}
                  currentValue={watch(FIELDS.NAME.NAME)}
                  error={!isNilOrEmpty(dotPath(FIELDS.NAME.NAME, errors))}
                  errorClass={classes.inputError}
                  labelClassName={classes.label}
                />
              </div> */}
              <div className={classes.email}>
                <InputText
                  name={FIELDS.EMAIL.NAME}
                  type="email"
                  label={FIELDS.EMAIL.LABEL}
                  inputClassName={classes.input}
                  register={register}
                  validations={FIELDS.EMAIL.VALIDATIONS}
                  currentValue={watch(FIELDS.EMAIL.NAME)}
                  error={!isNilOrEmpty(dotPath(FIELDS.EMAIL.NAME, errors))}
                  errorClass={classes.inputError}
                  labelClassName={classes.label}
                />
              </div>

              <FormButton
                submitClasses={submitClasses}
                onSubmit={handleSubmit(onSubmit)}
                submitting={submitting}
              />
            </form>
          )}

          {!isNilOrEmpty(ctaButton) && ctaButtonOnly && (
            <div className={classes.ctaButton}>
              <ContentfulButton data={ctaButton} dark />
            </div>
          )}
        </Animated>
      </div>
    </div>
  )
}
