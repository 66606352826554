import * as React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES } from '@contentful/rich-text-types'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const RichText = ({ data, size, linkWhite }) => {
  const classes = useStyles()

  const options = {
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          href={node.data.uri}
          className={linkWhite ? classes.linkWhite : classes.link}
          target="_blank"
          rel="noreferrer"
        >
          {node.content[0].value}
        </a>
      ),
    },
  }

  return (
    <Text size={size} className={classes.textWrapper}>
      {data && renderRichText(data, options)}
    </Text>
  )
}

RichText.defaultProps = {
  size: 'body1',
}
