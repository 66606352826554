import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  footerWrapper: {
    backgroundColor: COLOR.BLACK,
    display: 'flex',
    justifyContent: 'center',
    padding: [60, 0, 75],
    position: 'relative',
    [MQ.TABLET]: {
      padding: [120, 0, 117],
    },
    [MQ.DESKTOP]: {
      padding: [150, 0, 140],
    },
  },
  container: {
    display: 'flex',
    width: '71%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxWidth: 300,
    [MQ.TABLET]: {
      flexDirection: 'row',
      maxWidth: 1248,
      width: '79.7%',
    },
    [MQ.DESKTOP]: {
      width: '91%',
    },
  },
  logo: {
    width: '100%',
    [MQ.TABLET]: {
      width: '40%',
    },
    [MQ.DESKTOP]: {
      width: '28%',
      //marginLeft: 120,
    },
  },
  textBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [MQ.TABLET]: {
      width: '40%',
      justifyContent: 'space-between',
      maxWidth: 260,
    },
    [MQ.TABLET_LARGE]: {
      width: '44%',
      flexDirection: 'row',
      maxWidth: 'unset',
    },
    [MQ.DESKTOP]: {
      width: '40%',
    },
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    '& a:last-child': {
      marginBottom: 0,
    },
  },
  link: {
    textDecoration: 'none',
    color: COLOR.WHITE,
    marginBottom: 19,
    display: 'inline-block',
    textTransform: 'uppercase',
    alignSelf: 'flex-start',
  },
  contactLink: {
    textDecoration: 'none',
    color: COLOR.WHITE,
    marginBottom: 5,
    display: 'inline-block',
  },
  header: {
    color: COLOR.YELLOW,
    textTransform: 'uppercase',
    marginBottom: 10,
    [MQ.TABLET]: {
      marginBottom: 15,
    },
  },
  location: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: [30, 0, 0],
    [MQ.TABLET]: {
      padding: [40, 0, 0],
    },
    [MQ.TABLET_LARGE]: {
      padding: 0,
      width: '50%',
    },
  },
  mapLink: {
    marginBottom: 0,
    maxWidth: 120,
    [MQ.TABLET]: {
      maxWidth: 150,
    },
    [MQ.DESKTOP]: {
      maxWidth: 200,
    },
  },
  social: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingTop: 20,
    marginTop: 'auto',
    [MQ.TABLET]: {
      maxWidth: 300,
    },
  },
  socialLinks: {
    width: 30,
    height: 30,
    marginRight: 15,
    [MQ.TABLET]: {
      marginRight: 20,
    },

    '& span': {
      position: 'absolute',
      left: 10000,
    },
  },
  homeLink: {
    position: 'relative',
  },
  hidden: {
    position: 'absolute',
    left: -10000,
  },
  navLinks: {
    display: 'none',
    [MQ.TABLET]: {
      display: 'flex',
    },
  },
  socialSvg: {
    '& svg': {
      fill: COLOR.YELLOW,
    },
  },
})
