import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.WHITE,
  },
  container: {
    width: '80%',
    maxWidth: 325,
    margin: 'auto',
    padding: [60, 0, 0],
    [MQ.TABLET]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      maxWidth: 1150,
      width: '81.5%',
    },
    [MQ.DESKTOP]: {
      width: '73.75%',
      padding: [140, 0, 0],
    },
  },

  blogContainer: {
    width: '80%',
    maxWidth: 325,
    margin: 'auto',
    padding: [60, 0, 0],
    [MQ.TABLET]: {
      display: 'block',
      maxWidth: 1150,
      width: '81.5%',
    },
    [MQ.TABLET_LARGE]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [MQ.DESKTOP]: {
      width: '50%',
      padding: [140, 0, 0],
    },
  },
  tallText: {
    [MQ.TABLET]: {
      //alignItems: 'flex-start',
    },
  },
  flip: {
    [MQ.TABLET]: {
      flexDirection: 'row-reverse',
    },
  },
  image: {
    [MQ.TABLET]: {
      width: '47.5%',
    },
  },
  blogImage: {
    [MQ.TABLET]: {
      width: '55%',
      margin: 'auto',
    },
    [MQ.TABLET_LARGE]: {
      margin: 0,
      width: '45%',
    },
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [MQ.TABLET]: {
      height: '100%',
      width: '48%',
    },
  },
  blogTextBlock: {
    [MQ.TABLET]: {
      width: '55%',
      margin: 'auto',
    },
    [MQ.TABLET_LARGE]: {
      margin: 0,
      height: '100%',
      width: '48%',
    },
  },
  title: {
    margin: [28, 0, 0],
    color: COLOR.BLACK,
    [MQ.TABLET]: {
      margin: 0,
    },
    [MQ.DESKTOP]: {
      margin: [0, 0, 70],
      width: '80%',
    },
  },
  blogTitle: {
    [MQ.DESKTOP]: {
      margin: [0, 0, 0],
      width: '100%',
    },
  },
  body: {
    margin: [28, 0, 0],
    color: COLOR.BLACK,
  },
  blogBody: {
    margin: [20, 0, 0],
    color: COLOR.BLACK,
    [MQ.TABLET_LARGE]: {
      marginBottom: 30,
    },
  },
  button: {
    margin: [28, 0, 0],
    [MQ.TABLET]: {
      marginTop: 'auto',
      paddingTop: 28,
    },
  },
})
