import * as React from 'react'
import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useMedia } from 'react-use'
import { isNilOrEmpty } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'

export const Animated = ({ children, type, start }) => {
  const classes = useStyles()
  const refs = useRef([])
  const isTablet = useMedia('(min-width: 768px)', true)
  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    switch (type) {
      case 'fade-up-modal': {
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          onEnter: (batch) => {
            gsap.to(batch, {
              keyframes: [
                {
                  opacity: 1,
                  y: 0,
                  stagger: 0.1,
                  overwrite: true,
                  duration: 0.5,
                },
                {
                  className: classes.done,
                  clearProps: 'transform',
                },
              ],
            })
          },
        })

        break
      }
      case 'fade-up': {
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          onEnter: (batch) =>
            gsap.to(batch, {
              opacity: 1,
              y: 0,
              stagger: 0.1,
              overwrite: true,
              duration: 0.5,
            }),
        })

        break
      }
      case 'fade-up-hero': {
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          batchMax: 3,
          scrub: true,
          onEnter: (batch) =>
            gsap.to(batch, {
              opacity: 1,
              y: 0,
              stagger: 0.1,
              overwrite: true,
              delay: 0.5,
              duration: 0.5,
            }),
        })

        break
      }
      case 'fade-right': {
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          batchMax: 3,
          start: isTablet ? '200px bottom' : '50px bottom',
          onEnter: (batch) =>
            gsap.to(batch, {
              opacity: 1,
              x: 0,
              stagger: 0.1,
              overwrite: true,
              duration: 0.5,
            }),
        })
        break
      }
      case 'fade-left': {
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          batchMax: 3,
          start: isTablet ? '200px bottom' : '50px bottom',
          onEnter: (batch) =>
            gsap.to(batch, {
              opacity: 1,
              x: 0,
              stagger: 0.1,
              overwrite: true,
              duration: 0.5,
            }),
        })
        break
      }
      case 'fade-in': {
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          batchMax: 5,
          onEnter: (batch) =>
            gsap.to(
              batch,
              1.5,
              {
                opacity: 1,
                stagger: 0.1,
                overwrite: true,
              },
              0.5
            ),
        })
        break
      }

      default:
        ScrollTrigger.batch(refs.current, {
          interval: 0.1,
          batchMax: 3,
          onEnter: (batch) =>
            gsap.to(batch, 0.5, {
              opacity: 1,
              y: 0,
              stagger: 0.1,
              overwrite: true,
            }),
        })
        break
    }
  })

  const chooseClass = () => {
    switch (type) {
      case 'fade-up': {
        return classes.fadeUp
      }
      case 'fade-right': {
        return classes.fadeRight
      }
      case 'fade-left': {
        return classes.fadeLeft
      }
      case 'fade-up-hero': {
        return classes.fadeUpHero
      }
      case 'parallax-text': {
        return classes.parallax
      }
      case 'parallax-image': {
        return classes.parallaxImage
      }
      case 'parallax-wrapper': {
        return classes.parallaxWrapper
      }
      case 'fade-in': {
        return classes.fadeIn
      }
      default:
        return classes.fadeUp
    }
  }
  return (
    <>
      {React.Children.map(children, (Child, i) => {
        //checks for a child to allow for wrapping of conditional jsx elements
        const isChildPresent = !isNilOrEmpty(Child)
        const Tag = isChildPresent && Child.type

        //checks for a class on the child element to eliminate undefined classes
        const isClassPresent =
          isChildPresent && Child.props.className !== undefined
        const childClass = isClassPresent && `${Child.props.className}`

        return (
          Tag && (
            <Tag
              {...Child.props}
              ref={(element) => {
                refs.current[i] = element
              }}
              className={cn(childClass, `${chooseClass()}`)}
            />
          )
        )
      })}
    </>
  )
}

Animated.propTypes = {
  type: PropTypes.string,
  start: PropTypes.string,
}
Animated.defaultProps = {
  type: 'fade-up',
  start: '',
}
